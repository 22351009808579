import { Component, inject } from '@angular/core';
import { AxeptioService } from './services/axeptio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  #axeptioService: AxeptioService = inject(AxeptioService);

  ngOnInit(): void {
    // cookies banner
    this.#axeptioService.loadAxeptio();
  }
}

