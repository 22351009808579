import { Component, inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { tap } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [CommonModule, NzButtonModule],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.less',
})
export class UnauthorizedComponent {
  #authService: AuthService = inject(AuthService);
  #router: Router = inject(Router);

  goBack() {
    this.#authService.logout$().pipe(tap(_ => this.#router.navigate(['/auth']))).subscribe();
  }
}
