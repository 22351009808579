import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { switchMap, Observable, of, catchError, map } from 'rxjs';
import { AuthService } from '../services/auth.service';

export function authGuard(): Observable<boolean | UrlTree> {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.user$.pipe(
    switchMap(user => {
      if (!user) {
        return of(router.createUrlTree(['/auth']));
      }
      return authService.isAuthorized$.pipe(
        switchMap(isAuthorized => {
          if (!isAuthorized) {
            return authService.logout$().pipe(
              map(() => router.createUrlTree(['/unauthorized'])),
            );
          }
          return of(true);
        }),
      );
    }),
    catchError(() => of(router.createUrlTree(['/auth']))),
  );
}

export function noAuthGuard(): Observable<boolean | UrlTree> {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  return authService.user$.pipe(
    switchMap(currentUser => {
      if (!!currentUser) {
        return of(router.createUrlTree(['/']));
      }
      return of(true);
    }),
    catchError(() => {
      return of(true);
    }),
  );
}