import { inject, Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { catchError, from, map, Observable, of, startWith } from 'rxjs';
import { ConfirmationResponse } from '../../assets/responses/Confirmation.response';
import { CreatePinForUserOnCallRequest } from '../../assets/requests/auth/CreatePinForUserOnCall.request';
import { LoginForUserWithPinRequest } from '../../assets/requests/auth/LoginForUserWithPin.request';
import { LoginUserResponse } from '../../assets/responses/LoginUser.response';
import { GetBeneficiaryVouchersInformationsResponse } from '../../assets/responses/discreteVouchers/GetBeneficiaryVouchersInformations.response';
import {
  GetBusinessMonthlyVoucherSummaryRequest,
} from '../../assets/requests/discreteVouchers/GetBusinessMonthlyVoucherSummary.request';
import { MonthlyVoucherSummaryModel } from '../../assets/models/discreteVouchers/VoucherSummary.model';
import {
  GenerateBusinessVouchersInvoiceRequest
} from 'mobile-benef/src/assets/requests/discreteVouchers/GenerateBusinessVouchersInvoice.request';
import { BusinessVouchersInvoiceModel } from 'mobile-benef/src/assets/models/accountingDocuments/BusinessAccountingDocument.model';
import { ContractedAgencyModel } from 'mobile-benef/src/assets/models/discreteVouchers/ContractedAgency.model';

type BackendClient = {
  'AUTH-checkIsActivatedUser_onCall': {
    request: { email: string },
    response: { isActivated: boolean }
  },
  'AUTH-createPinForUser_onCall': {
    request: CreatePinForUserOnCallRequest,
    response: ConfirmationResponse,
  },
  'AUTH-loginForUserWithPin_onCall': {
    request: LoginForUserWithPinRequest,
    response: LoginUserResponse,
  },
  'DISCRETE_VOUCHERS-getBeneficiaryVouchersInformations_onCall': {
    request: { beneficiaryUid: string },
    response: GetBeneficiaryVouchersInformationsResponse
  },
  'DISCRETE_VOUCHERS-getBusinessMonthlyVoucherSummary_OnCall': {
    request: GetBusinessMonthlyVoucherSummaryRequest,
    response: MonthlyVoucherSummaryModel
  },
  'DISCRETE_VOUCHERS-generateBusinessVouchersInvoice_OnCall': {
    request: GenerateBusinessVouchersInvoiceRequest,
    response : BusinessVouchersInvoiceModel
  },

  'BUSINESSES-getContractedAgenciesOfBusiness_OnCall' : {
    request : { businessId : string },
    response: ContractedAgencyModel
  }

}

export enum State {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface LoadingState<T> {
  state: State;
  data?: T;
  error?: any;
}

@Injectable({
  providedIn: 'root',
})
export class CallerService {
  #functions: Functions = inject(Functions);

  onCall$<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(functionName: FuncName, request: Req): Observable<Res> {
    return from(httpsCallable<Req, Res>(this.#functions, functionName)(request)).pipe(
      map((result: HttpsCallableResult<Res>) => result.data),
    );
  }

  onCallWithLoadingState$<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(functionName: FuncName, request: Req): Observable<LoadingState<Res>> {
    return from(httpsCallable<Req, Res>(this.#functions, functionName)(request)).pipe(
      map((result: HttpsCallableResult<Res>) => ({ state: State.Loaded, data: result.data })),
      catchError(error => of({ state: State.Error, error })),
      startWith({ state: State.Loading }),
    );
  }
}
