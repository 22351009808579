import { inject, Injectable } from '@angular/core';
import {
  Auth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  user,
  User,
  UserCredential,
  IdTokenResult,
} from '@angular/fire/auth';
import { combineLatest, filter, from, map, Observable, switchMap, take, tap, of } from 'rxjs';
import { CallerService } from './caller.service';
import { CreatePinForUserOnCallRequest } from '../../assets/requests/auth/CreatePinForUserOnCall.request';
import { ConfirmationResponse } from '../../assets/responses/Confirmation.response';
import { LoginForUserWithPinRequest } from '../../assets/requests/auth/LoginForUserWithPin.request';
import { FirestoreService } from './firestore.service';
import { App } from '../../assets/types/UserRole.type';
import { UserModel } from '../../assets/models/user/User.model';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth: Auth = inject(Auth);
  #caller: CallerService = inject(CallerService);
  #fs: FirestoreService = inject(FirestoreService);

  user$: Observable<User | null> = user(this.auth);

  logout(): Promise<void> {
    return signOut(this.auth);
  }

  logout$(): Observable<void> {
    return from(this.logout());
  }

  login$(email: string, password: string): Observable<UserModel> {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      take(1),
      filter((credentials: UserCredential): credentials is UserCredential => !!credentials),
      switchMap((credentials: UserCredential) =>
        this.#fs.get$('USERS', credentials.user.uid).pipe(
          filter((user): user is UserModel => !!user),
        ),
      ),
    );
  }

  getUserOnCall<T>(uid: string): Observable<T | undefined> {
    return this.#fs.get$('USERS', uid);
  }

  createPinForUser$(email: string): Observable<ConfirmationResponse> {
    const request: CreatePinForUserOnCallRequest = {
      email, appTarget: 'webBusiness',
    };
    return this.#caller.onCall$('AUTH-createPinForUser_onCall', request);
  }

  loginForUserWithPin$(request: LoginForUserWithPinRequest): Observable<UserModel> {
    return this.#caller.onCall$('AUTH-loginForUserWithPin_onCall', request).pipe(
      take(1),
      filter(Boolean),
      switchMap(({ user, token }) => from(signInWithCustomToken(this.auth, token))
        .pipe(
          filter(Boolean),
          switchMap((user) => this.getUserOnCall(user.user.uid)
            .pipe(
              filter((user): user is UserModel => !!user),
              // tap((userData: UserModel) => this.#userService.setUserFromDb$(userData)),
            )),
        ),
      ),
    );
  }

  isAuthorized$: Observable<boolean> = this.user$.pipe(switchMap((user) => {
    if (!user) {
      return of(false);
    }
    return from(user.getIdTokenResult()).pipe(
      map(({ claims }: Pick<IdTokenResult, 'claims'>) => {
        const { roles = [] } = claims as any;
        return roles.includes('BUSINESS_ADMIN');
      }),
    );
  }));

  checkIsActivatedUser$(email: string): Observable<{ isActivated: boolean }> {
    return this.#caller.onCall$('AUTH-checkIsActivatedUser_onCall', { email });
  }

  updateUserPassword$(password: string): Observable<UserModel> {
    return this.user$.pipe(
      take(1),
      filter(Boolean),
      switchMap((user: User) => {
        return combineLatest([
          from(updatePassword(user, password)),
          this.#fs.setDoc$('USERS', user.uid, { isActivated: true }),
        ]).pipe(
          switchMap(([_]) => this.getUserOnCall(user.uid)
            .pipe(
              filter((user): user is UserModel => !!user),
              map((user: UserModel) => user),
            )),
        );
      }),
    );
  }

  acceptedCgu$(): Observable<void> {
    return this.user$.pipe(
      take(1),
      filter((user: User | null): user is User => Boolean(user)),
      switchMap((user: User) =>
        this.#fs.get$('USERS', user.uid).pipe(
          filter((userFromDb): userFromDb is UserModel => Boolean(userFromDb)),
          switchMap((userFromDb: UserModel) => {
            const updatedHasAcceptedCguByApp: App[] = [...(userFromDb.hasAcceptedCguByApp || []), 'webBusiness'];
            return this.#fs.setDoc$('USERS', user.uid, { hasAcceptedCguByApp: updatedHasAcceptedCguByApp });
          }),
        ),
      ),
    );
  }

  // VALIDATORS PASSWORD
  containsUpperCaseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasUpperCase: boolean = /[A-Z]/.test(control.value);
      return hasUpperCase ? null : { noUpperCase: true };
    };
  }

  containsLowerCaseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasLowerCase: boolean = /[a-z]/.test(control.value);
      return hasLowerCase ? null : { noLowerCase: true };
    };
  }

  containsDigitValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasDigit: boolean = /\d/.test(control.value);
      return hasDigit ? null : { noDigit: true };
    };
  }

  containsSpecialCharValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasSpecialChar: boolean = /[^\da-zA-Z]/.test(control.value);
      return hasSpecialChar ? null : { noSpecialChar: true };
    };
  }

}
