import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { Auth, connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, Firestore, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, Functions, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import fr from '@angular/common/locales/fr';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    IconsProviderModule,
    NzModalModule,
    NzLayoutModule,
    NzGridModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.name === 'local') {
        connectStorageEmulator(storage, '127.0.0.1', 9199);
      }
      return storage;
    }),
    // provideAppCheck(() => {
    //   (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = environment.name === 'local';
    //   return initializeAppCheck(getApp(), {
    //     provider: new ReCaptchaEnterpriseProvider(environment.recaptchaEnterpriseSiteKey),
    //     isTokenAutoRefreshEnabled: true,
    //   });
    // }),
    provideAuth(() => {
      const auth: Auth = getAuth();
      if (environment.name === 'local') {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore: Firestore = getFirestore();
      if (environment.name === 'local') {
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions: Functions = getFunctions();
      if (environment.name === 'local') {
        connectFunctionsEmulator(functions, '127.0.0.1', 4089);
      }
      functions.region = 'europe-west3';
      return functions;
    }),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()), NzButtonModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },

    {
      provide: NZ_I18N,
      useValue: fr_FR,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
