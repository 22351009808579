import { inject, Injectable } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  DocumentSnapshot,
  query,
  where,
  orderBy,
  collectionData,
  Timestamp,
  PartialWithFieldValue,
  DocumentData,
  setDoc
} from '@angular/fire/firestore';
import { map, from, Observable } from 'rxjs';
import { getDocs, OrderByDirection, WhereFilterOp } from '@firebase/firestore';

export type ListOfCollections =
  | 'VALIDATED_MASKS'
  | 'AGENCIES'
  | 'AGENCY_ADMINS'
  | 'BENEFICIARIES'
  | 'COMPANIES'
  | 'EMAIL_EVENTS'
  | 'INVOICES'
  | 'ORDERS'
  | 'PAYBACKS'
  | 'PINS'
  | 'PROVISIONS'
  | 'USERS'
  | 'VAT'
  | 'OPEN_BANKING_BANKS'
  | 'SCAN_TRANSACTIONS_TEST'
  | 'APP_AVAILABILITY'
  | 'OPEN_BANKING_TOKENS'
  | 'COUNTERS'
  | 'PUBLIC_HOLIDAYS'
  | 'BUSINESSES'
  | 'BUSINESS_ADMINS'
  | 'BUSINESS_CASHIER'
  | 'COUNTRY_FLAGS'
  | 'STATEMENTS'
  | 'PAYMENT_TRANSACTIONS'
  | 'YEARLY_MILLESIME_METADATA'
  | 'CONTRACT_BASED_MILLESIME_METADATA'
  | 'MILLESIME_REPORTS'
  | 'OPEN_BANKING_SYNC_STATUS'
  | 'ANALYTICS'
  ;


export type QueryWhereElement<T> = {
  fieldName: keyof T;
  operator: WhereFilterOp;
  value: unknown;
  //T[keyof T] | T[keyof T][]
}

type Order<T> = {
  fieldName: keyof T,
  direction: OrderByDirection
}

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  firestore: Firestore = inject(Firestore);

  get$<T>(collectionName: string, docUid: string): Observable<T | undefined> {
    return from(getDoc(doc(collection(this.firestore, collectionName), docUid))).pipe(
      map((snapshot: DocumentSnapshot) => snapshot.data() as T | undefined),
    );
  }

  getAll$<T>(collectionName: string): Observable<T[]> {
    return collectionData(collection(this.firestore, collectionName)) as Observable<T[]>;
  }

  getWhere$<T>(collectionName: string, queryWhere: QueryWhereElement<T>[] = [], order?: keyof T | Order<T>): Observable<T[]> {
    const c = collection(this.firestore, collectionName);

    let q = query(c);
    for (const whereOption of queryWhere) {
      q = query(q, where(whereOption.fieldName as string, whereOption.operator, whereOption.value));
    }

    if (!!order) {
      if (typeof order === 'string') {
        q = query(q, orderBy(order));
      } else {
        const o = order as Order<T>;
        q = query(q, orderBy(o.fieldName as string, o.direction));
      }
    }

    return from(getDocs(q)).pipe(
      map((res) => res.docs.map(d => d.data() as T)),
    );
  }

  setDoc$(collectionName: ListOfCollections, docUid: string, data: PartialWithFieldValue<DocumentData>): Observable<void> {
    const docRef = doc(this.firestore, collectionName, docUid);
    return from(setDoc(docRef, data, { merge: true }));
  }

  static convertTimestampOrDateToLocaleDateString(obj: any): any {
    if (obj instanceof Timestamp) {
      return obj.toDate().toLocaleDateString('fr-FR');
    } else if (obj instanceof Array) {
      return obj.map(FirestoreService.convertTimestampOrDateToLocaleDateString);
    } else if (obj instanceof Date) {
      return obj.toLocaleDateString('fr-FR');
    } else if (typeof obj === 'object' && obj !== null) {
      const convertedObj: Record<string, any> = {};
      Object.entries(obj).forEach(([key, value]) => {
        convertedObj[key as string] = FirestoreService.convertTimestampOrDateToLocaleDateString(value);
      });
      return convertedObj;
    }
    return obj;
  }
}
